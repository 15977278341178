'use state'
import React, { FC } from 'react'
import { VStack } from '@chakra-ui/react'
import { GrandLink, GrandLinkProps, GrandText } from 'ui'
import { StenlundsCompanyInfo } from '#/src/custom/components/StenlundsCompanyInfo'

export interface IContactTextProps {}

const ContactLink = (props: GrandLinkProps) => (
  <GrandLink
    {...props}
    _hover={{
      textDecoration: 'none'
    }}
    textDecoration="underline"
  />
)

const contacts = [
  {
    title: 'Telefon:',
    content: (
      <VStack alignItems="flex-start" gap={4}>
        <GrandText>
          Stockholm - Mälardalen:
          <br />
          <ContactLink href="tel:08838090">08-83 80 90</ContactLink>
        </GrandText>
      </VStack>
    )
  },
  {
    title: 'E-post:',
    content: (
      <VStack alignItems="flex-start" gap={1}>
        <ContactLink href="mailto:info@stenlundsprofessional.se">
          info@stenlundsprofessional.se
        </ContactLink>
        <ContactLink href="mailto:kundtjanst@stenlundsprofessional.se">
          kundtjanst@stenlundsprofessional.se
        </ContactLink>
      </VStack>
    )
  }
]

const ContactText: FC<IContactTextProps> = () => {
  return (
    <VStack
      pb={{ base: 10, lg: 0 }}
      pr={{ base: 0, lg: 20 }}
      w={{ base: '100%', lg: '50%' }}
      alignItems="flex-start"
      gap={10}>
      <GrandText fontSize={32}>Kontakta oss</GrandText>
      <GrandText>
        Vi är väldigt stolta över att vi har mer än 50 år i branschen och massor av nöjda kunder med
        lyckade projekt i bagaget, stora som små. Därför erbjuder vi kostnadsfri rådgivning och
        hoppas att det blir starten på ett långt och givande samarbete!
      </GrandText>

      <VStack alignItems="flex-start" gap={10}>
        {contacts.map(({ title, content }) => (
          <VStack key={title} alignItems="flex-start" fontSize="lg">
            <GrandText as="b">{title}</GrandText>
            {content}
          </VStack>
        ))}
      </VStack>

      <StenlundsCompanyInfo />
    </VStack>
  )
}

export default ContactText
